<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            type="search"
            codeGroupCd="PATROL_TYPE_FST_CD"
            itemText="codeName"
            itemValue="code"
            label="상위구분"
            name="patrolFstCd"
            v-model="searchParam.patrolFstCd">
          </c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            type="search"
            codeGroupCd="PATROL_TYPE_SEC_CD"
            itemText="codeName"
            itemValue="code"
            label="하위구분"
            name="patrolSecCd"
            v-model="searchParam.patrolSecCd">
          </c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-dept
            label="추진부서"
            name="deptCd"
            v-model="searchParam.deptCd"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 추진기간 -->
          <c-datepicker
            :range="true"
            label="추진기간"
            defaultStart="-6M"
            defaultEnd="6M"
            name="period"
            v-model="searchParam.period"
          />
        </div>
          <!-- 진행단계 -->
        <!-- <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :comboItems="statusItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="patrolCompleteFlag"
            label="LBLSTEPPROGRESS"
            v-model="searchParam.patrolCompleteFlag"
          ></c-select>
        </div> -->
      </template>
    </c-search-box>
    
    <div class="card cardcontents">
      <!-- <div class="card-header q-table-header">
        TBM
      </div> -->
      <div class="card-body qtable-card-body">
        <q-table
          grid
          title=""
          :data="grid.data"
          :columns="grid.columns"
          hide-header
          hide-bottom
          :rows-per-page-options="[0]"
          virtual-scroll
        >
          <template v-slot:item="props">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
              <q-card class="mobileTableCardLayer" @click="linkClick(props.row)">
                <q-card-section class="grid-card-etc">
                  <div :class="['txt-box-grid', 'text-positive-box']">
                    {{ props.row.regDt }}
                  </div>
                  <div v-if="props.row.permitNo" :class="['txt-box-grid', 'text-gray-box']">
                    {{ props.row.deptName }}
                  </div>
                </q-card-section>
                <q-card-section>
                  <div class="text-grid-title q-mt-sm q-mb-xs">{{ props.row.patrolName }}</div>
                </q-card-section>
                <q-card-section class="grid-card-etc">
                  <div class="text-grid-etc">
                    {{ props.row.patrolFstName }} 
                  </div>
                  <div class="text-grid-etc">
                    {{ props.row.patrolSecName }} 
                  </div>
                </q-card-section>
              </q-card>
            </div>
          </template>
        </q-table>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3" v-if="grid.data.length === 0">
          <q-card class="mobileTableCardLayer">
            <q-card-section horizontal class="bg-grey-2 text-center">
              <q-card-section class="q-pt-xs">
                <div class="text-grid-title q-mt-sm q-mb-xs">데이터가 존재하지 않습니다.</div>
              </q-card-section>
            </q-card-section>
          </q-card>
        </div>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'patrol',
  props: {
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            // 사업장
            label: 'LBLPLANT',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            name: 'regDt',
            field: 'regDt',
            // 등록일
            label: '등록일',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            name: 'patrolFstName',
            field: 'patrolFstName',
            // 상위구분
            label: '상위구분',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            name: 'patrolSecName',
            field: 'patrolSecName',
            // 하위구분
            label: '하위구분',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            name: 'patrolName',
            field: 'patrolName',
            // 제목
            label: '제목',
            align: 'left',
            sortable: true,
            type: 'link',
            style: 'width:200px',
          },
          {
            name: 'deptName',
            field: 'deptName',
            // 부서
            label: '추진부서',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            name: 'patrolDate',
            field: 'patrolDate',
            // 추진일정
            label: '추진일정',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          // {
          //   name: 'patrolCompleteFlagName',
          //   field: 'patrolCompleteFlagName',
          //   // 진행단계
          //   label: 'LBLPROGRESS',
          //   align: 'center',
          //   sortable: true,
          //   style: 'width:100px',
          // },
          // {
          //   // 결재진행단계
          //   name: 'approvalStatusName',
          //   field: 'approvalStatusName',
          //   label: 'LBL00001609',
          //   align: 'center',
          //   sortable: true,
          //   style: 'width:100px',
          // },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        deptCd: null,
        patrolFstCd: null,
        patrolSecCd: null,
        useFlag: 'Y',
        startYmd: '',
        endYmd: '',
        period: [],
      },
      statusItems: [
        { code: "N", codeName: "점검중" },
        { code: "Y", codeName: "점검완료" },
      ],
      searchUrl: '',
      editable: true,
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sai.patrol.list.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      if (this.searchParam.period && this.searchParam.period.length === 2) {
        this.searchParam.startYmd = this.searchParam.period[0];
        this.searchParam.endYmd = this.searchParam.period[1];
      } else {
        this.searchParam.startYmd = '';
        this.searchParam.endYmd = '';
      }
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, index) {
      this.popupOptions.title = '관리책임자 패트롤 상세'; // 관리책임자 패트롤 상세
      this.popupOptions.param = {
        saiPatrolId: row ? row.saiPatrolId : '',
      };
      this.popupOptions.target = () => import(`${'./patrolDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
